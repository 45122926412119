<template>
  <div v-if="shouldBeVisible" class="countdown-pill">
    <img src="~/assets/img/countdown-clock.svg" />
    <div class="countdown-container">
      <span>{{ $t('components.countdown.title_reward') }}</span>
      <div class="countdown-box">
        <div class="digit-container">
          <span class="digit">{{ getHours }}</span>
          <span>{{ $t('components.countdown.hour') }}</span>
        </div>
        <div class="delimiter-container">
          <span class="delimiter">:</span>
          <span class="invisible">:</span>
        </div>
        <div class="digit-container">
          <span class="digit">{{ getMinutes }}</span>
          <span>{{ $t('components.countdown.minute') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      end: '',
      timer: null,
      timeRemaining: '',
      now: '',
    };
  },
  computed: {
    getHours() {
      const hour = Math.floor(this.timeRemaining / (1000 * 60 * 60));

      return hour >= 10 ? hour : '0' + hour;
    },
    getMinutes() {
      const minute = Math.floor(
        (this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );

      return minute >= 10 ? minute : '0' + minute;
    },
    shouldBeVisible() {
      return parseInt(this.getHours) < 24 && this.timeRemaining > 0;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.end = this.target.replace(/\s/, 'T') + 'Z';
    this.end = new Date(this.end);

    const now = new Date();
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );

    let nowTZ = new Date(
      nowUTC.toLocaleString('en-US', {
        timeZone: 'Europe/Vienna',
      })
    );
    nowTZ = Date.UTC(
      nowTZ.getFullYear(),
      nowTZ.getMonth(),
      nowTZ.getDate(),
      nowTZ.getHours(),
      nowTZ.getMinutes(),
      nowTZ.getSeconds(),
      nowTZ.getMilliseconds()
    );
    this.now = new Date(nowTZ);

    this.timeRemaining = this.end - this.now;

    if (this.timeRemaining > 0) {
      this.timer = setInterval(
        () => (this.timeRemaining = this.end - this.now),
        1000
      );
    } else {
      this.timeRemaining = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.countdown-pill {
  @apply flex p-1 uppercase bg-white rounded-full;
  font-size: 0.5rem;
}

.countdown-container {
  @apply pl-2 pr-4 text-center;
}

.countdown-box {
  @apply flex items-center self-center pt-1;
}

.digit-container {
  @apply flex flex-col items-center;

  .digit {
    @apply text-2xl font-black leading-4 font-theme-title;
  }
}

.delimiter-container {
  @apply flex flex-col items-start;

  .delimiter {
    @apply px-1 text-2xl font-black leading-4 font-theme-title;
  }
}
</style>
